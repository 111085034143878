<template>
  <div></div>
</template>
<script>
export default {
  name: "LogoutView",
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.callbackRoute = from.path;
    });
  },
  created() {
    this.$store.dispatch("logout").finally(() => {
      sessionStorage.clear();
      window.location.href =
        this.$store.getters["logout_url"] +
        "?callback=" +
        window.btoa(process.env.VUE_APP_URL + this.callbackRoute);
    });
  },
};
</script>
